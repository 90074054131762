import type { IngredientStory } from './IngredientStory'
import type { ListItemBlock } from './blocks/ListItemBlock'
import type { StoryblokAsset, StoryblokHasMany, StoryblokStory } from './StoryblokStory'
import { getStoryblokImage } from '@/utils/getStoryblokImage'

export interface IngredientBreakdown {
  name: string
  percent: number
}

export const CONTENT_TYPE = 'meal' as const

export type MealStory = StoryblokStory<
  typeof CONTENT_TYPE,
  {
    display_name: string
    prefix?: string
    extended_prefix?: string
    image: StoryblokAsset
    bowl: StoryblokAsset
    pouch: StoryblokAsset
    icon: StoryblokAsset
    url: string
    description: string
    tagline: string
    key_ingredients: StoryblokHasMany<IngredientStory>
    ingredients: StoryblokHasMany<IngredientStory>
    vitamins_minerals: StoryblokHasMany<IngredientStory>
    ingredient_breakdown: IngredientBreakdown[]
    allergens: string[]
    key_features: ListItemBlock[]
    key_notes: ListItemBlock[]
    is_therapeutic: boolean
  }
>

export const getMealPouchImage = (meal: MealStory): string | undefined => {
  if (meal.content.pouch) {
    return getStoryblokImage(meal.content.pouch.filename, {
      width: 640,
    })
  }
}

export const getMealBowlImage = (meal: MealStory): string | undefined => {
  if (meal.content.bowl) {
    return getStoryblokImage(meal.content.bowl.filename, {
      width: 640,
    })
  }
}

export const getMealIconImage = (meal: MealStory): string | undefined => {
  if (meal.content.icon) {
    return meal.content.icon.filename
  }
}
