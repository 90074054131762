<script setup lang="ts">
import MealSummaryIngredientBreakdown from './MealSummaryIngredientBreakdown.vue'
import type { IngredientStory } from '@/models/storyblok/IngredientStory'
import type { MealStory } from '@/models/storyblok/MealStory'
import { getStoryblokImage } from '@/utils/getStoryblokImage'
import { isStory } from '@/models/storyblok/StoryblokStory'

defineProps<{
  meal: MealStory
}>()

const getIngredientImage = (ingredient: IngredientStory): string | undefined => {
  if (!ingredient.content.image) {
    return undefined
  }

  return getStoryblokImage(ingredient.content.image.filename, { height: 72 })
}
</script>

<template>
  <div class="meal">
    <div class="tw-space-y-2">
      <h2 class="tw-font-stylized tw-text-6xl tw-leading-tighter">
        <span class="tw-block tw-text-4xl">{{ meal.content.prefix }}</span>
        <span class="tw-text-green tw-block">{{ meal.content.display_name }}</span>
      </h2>

      <p class="tw-font-bold">{{ meal.content.tagline }}</p>

      <div class="meal-description">
        <p>{{ meal.content.description }}</p>
      </div>
    </div>

    <div>
      <h4 class="meal-heading">
        <span>Key ingredients</span>
      </h4>

      <div class="meal-ingredients">
        <template v-for="ingredient in meal.content.key_ingredients">
          <div v-if="isStory(ingredient)" :key="ingredient.id" class="meal-ingredient">
            <img
              v-if="ingredient.content.image"
              class="meal-ingredient-image"
              :src="getIngredientImage(ingredient)"
              :alt="ingredient.name"
            />
            <h5 class="meal-ingredient-name">{{ ingredient.name }}</h5>
          </div>
        </template>
      </div>
    </div>

    <div class="meal-breakdown">
      <h4 class="meal-heading">Ingredient breakdown</h4>

      <MealSummaryIngredientBreakdown
        v-if="meal.content.ingredient_breakdown"
        :breakdown="meal.content.ingredient_breakdown"
      />
    </div>
  </div>
</template>

<style scoped>
.meal {
  @apply tw-text-base tw-space-y-6 tw-max-w-xs tw-mx-auto lg:tw-mx-0 tw-text-center lg:tw-text-left;
}

.meal-heading {
  @apply tw-uppercase tw-text-green tw-text-xs tw-flex tw-items-center tw-gap-4 tw-font-bold tw-mb-4;
}

.meal-heading::before,
.meal-heading::after {
  content: '';
  @apply tw-bg-mint-green tw-h-[1px] tw-flex-1 tw-block;
}

.meal-ingredients {
  @apply tw-flex tw-gap-4 tw-items-start;
}

.meal-ingredient {
  @apply tw-space-y-4;
}

.meal-ingredient-image {
  @apply tw-h-10 tw-object-contain tw-object-center tw-mx-auto tw-w-20;
}

.meal-ingredient-name {
  @apply tw-font-bold tw-text-xs tw-text-center tw-leading-tight;
}

.meal-description {
  @apply tw-line-clamp-3;
  min-height: 3lh;
}
</style>
