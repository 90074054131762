<script setup lang="ts">
import type { IngredientBreakdown } from '@/models/storyblok/MealStory'

defineProps<{
  breakdown: IngredientBreakdown[]
}>()
</script>

<template>
  <div class="breakdown">
    <div
      v-for="ingredient in breakdown"
      :key="ingredient.name"
      class="ingredient"
      :style="{ width: `${ingredient.percent}%` }"
    >
      <div class="ingredient-label">{{ ingredient.percent }}% {{ ingredient.name }}</div>
    </div>
  </div>
</template>

<style scoped>
.breakdown {
  @apply tw-flex tw-my-8;
}
.ingredient {
  @apply tw-relative tw-text-white tw-text-center tw-h-7 tw-text-xs tw-items-center tw-flex tw-justify-center first:tw-rounded-l-full last:tw-rounded-r-full tw-whitespace-nowrap tw-bg-current;
}

.ingredient:first-child {
  @apply tw-text-dark;
}

.ingredient {
  @apply tw-text-orange;
}

.ingredient:last-child {
  @apply tw-text-green;
}

.ingredient-label {
  @apply tw-absolute tw-left-1/2 -tw-translate-x-1/2;
}

.ingredient:nth-child(odd) .ingredient-label {
  @apply tw-top-full tw-mt-3;
}

.ingredient:nth-child(even) .ingredient-label {
  @apply tw-bottom-full tw-mb-2;
}

.ingredient-label::before {
  content: '';
  @apply tw-bg-current tw-w-[1px] tw-absolute tw-h-2 tw-left-1/2;
}

.ingredient:nth-child(odd) .ingredient-label::before {
  @apply tw-bottom-full tw-mb-1;
}

.ingredient:nth-child(even) .ingredient-label::before {
  @apply tw-top-full;
}
</style>
